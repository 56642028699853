import "@/js/homepage-pharos-init";
import Glide, { Controls, Swipe, Breakpoints } from "@glidejs/glide/dist/glide.modular.esm";
import initializeHomepageQueryBuilder from "@/js/components/homepageQueryBuilder";
import {mountGlobalMfes} from "@/js/components/globalMfesPoc";

const setSliderNavigationButtonLabels = (sliderClass, currentSlide, slideCount) => {
    const prevButton = document.querySelector(`.${sliderClass}__previous-button`);
    const nextButton = document.querySelector(`.${sliderClass}__next-button`);
    prevButton.label = `Previous, image ${
        currentSlide === 0 ? slideCount : currentSlide - 1
    } of ${slideCount}`;
    nextButton.label = `Next, image ${
        currentSlide === slideCount ? "1" : currentSlide
    } of ${slideCount}`;
};

const mountHeroSlider = () => {
    const slider = new Glide(".hero-slider", {
        type: "slider",
        perView: 1,
        focusAt: 0,
        gap: 0,
        keyboard: true,
        animationDuration: 1000,
    }).mount({ Controls, Swipe });

    slider.on("run.after", () => {
        const currentSlide = document.querySelector("section.hero-slider li.glide__slide--active");
        const slideCount = document.querySelectorAll("li.glide__slide--hero").length;
        if (currentSlide) {
            const currentSlideNumber = parseInt(currentSlide.getAttribute("data-slide-index"));
            document.querySelector(
                ".hero-slider__label--pagination",
            ).textContent = `Image ${currentSlideNumber} of ${slideCount}`;
            document.querySelector("#caption-link").href = currentSlide.getAttribute("data-url");
            document.querySelector("#caption-link").textContent = currentSlide.getAttribute("data-collection");
            document.querySelector("#caption").textContent = currentSlide.getAttribute("data-caption");
            document.querySelector("#copyright").textContent = currentSlide.getAttribute("data-copyright");
            
            setSliderNavigationButtonLabels("hero-slider", currentSlideNumber, slideCount);
        }
    });
};

const getPublicHealthSliderPerView = () => {
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    var perView = 5;
    if (width <= 425) {
        perView = 1;
    } else if (width <= 600) {
        perView = 2;
    } else if (width <= 768) {
        perView = 3;
    } else if (width <= 1024) {
        perView = 4;
    }
    return perView;
};

const mountPublicHealthSlider = () => {
    const slider = new Glide(".thematic-slider", {
        type: "slider",
        perView: 5,
        bound: true,
        focusAt: 0,
        gap: 32,
        keyboard: true,
        animationDuration: 400,
    }).mount({ Controls, Swipe, Breakpoints });

    slider.update({ perView: getPublicHealthSliderPerView() });

    window.onresize = () => {
        slider.update({ perView: getPublicHealthSliderPerView() });
    };
};

document.addEventListener("DOMContentLoaded", () => {
    "use strict";

    let dataLayer = (window.dataLayer = window.dataLayer || []);
    dataLayer.push({ event: "optimize.homepage.activate" });

    initializeHomepageQueryBuilder();

    if (window.globalFlags?.mountGlobalMfesPoc) {
        mountGlobalMfes();
    }
});

window.addEventListener("load", () => {
    mountHeroSlider();
    mountPublicHealthSlider();
});
