import registerComponents from "@ithaka/pharos/lib/utils/registerComponents";

const appPrefix = "search-ui";


import("@ithaka/pharos").then((module) => {
    if (!customElements.get(`${appPrefix}-pharos-tab`)) {
        registerComponents(appPrefix, [ module.PharosTab]);
    }

    if (!customElements.get(`${appPrefix}-pharos-tabs`)) {
        registerComponents(appPrefix, [ module.PharosTabs]);
    }

    if (!customElements.get(`${appPrefix}-pharos-heading`)) {
        registerComponents(appPrefix, [ module.PharosHeading]);
    }

    if (!customElements.get(`${appPrefix}-pharos-layout`)) {
        registerComponents(appPrefix, [ module.PharosLayout]);
    }

    if (!customElements.get(`${appPrefix}-pharos-icon`)) {
        registerComponents(appPrefix, [ module.PharosIcon]);
    }

    if (!customElements.get(`${appPrefix}-pharos-image-card`)) {
        registerComponents(appPrefix, [ module.PharosImageCard]);
    }

    if (!customElements.get(`${appPrefix}-pharos-link`)) {
        registerComponents(appPrefix, [ module.PharosLink]);
    }

    if (!customElements.get(`${appPrefix}-pharos-button`)) {
        registerComponents(appPrefix, [ module.PharosButton]);
    }
});
