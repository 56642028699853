import { loadRegisteredModule } from "@ithaka/federated-module-loader";
import {
    CaptainsLogger,
    SessionEnrichment,
} from "@ithaka/frontend-log-sdk";

const captainsLogger = new CaptainsLogger("srchx", "search");

function initializeHomepageQueryBuilder () {
    const qbMountPoint = document.getElementById("query-builder-mount-point");
    if (qbMountPoint) {
        loadRegisteredModule("mfeQueryBuilder/MfeQueryBuilder")
            .then((module) => {
                module.mount(qbMountPoint, {
                    pageType: "homepage",
                    showScopeSelector: true,
                    showSearchCount: true,
                    value: "",
                    margin: true,
                    name: "Query",
                    iconName: "search-button",
                    onSubmit: (data) => {
                        // eslint-disable-next-line no-undef
                        dataLayer.push({
                            "event": "searchPerformed",
                            "location": "Home",
                            "value": data.value,
                        });

                        captainsLogger.log({
                            eventType: "global_search_submitted",
                            enrichments: [
                                new SessionEnrichment(),
                            ],
                            additionalFields: {
                                action: data.scope,
                                value: data.value,
                            },
                        });
                    },
                });
            });
    }
}

export default initializeHomepageQueryBuilder;
